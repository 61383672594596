import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["submissionButton", "columnHeader"]

  connect() {
    if (this.hasColumnHeaderTarget){
      this.findLongestHeader()
    }

    this.evaluateWrap()
  }

  checkButtonWrapping() {
    this.submissionIsWrapping = this.submissionButtonTarget.scrollWidth > this.submissionButtonTarget.clientWidth

    if (this.submissionIsWrapping){
      this.minButtonScrollWidth = this.submissionButtonTarget.scrollWidth
      this.submissionButtonTargets.forEach(target => target.classList.add("icon-only"))
    }
  }

  checkButtonNotWrapping() {
    this.submissionIsWrapping = this.minButtonScrollWidth > this.submissionButtonTarget.clientWidth

    if (!this.submissionIsWrapping){
      this.submissionButtonTargets.forEach(target => target.classList.remove("icon-only"))
    }
  }

  findLongestHeader() {
    this.largestHeaderEl = this.columnHeaderTarget

    this.columnHeaderTargets.forEach(header => {
      if (header.scrollWidth > this.largestHeaderEl.scrollWidth) this.largestHeaderEl = header
    })
  }

  checkHeaderWrapping() {
    var wrap_width = this.largestHeaderEl.scrollWidth + 10

    this.headerIsWrapping = wrap_width > this.largestHeaderEl.parentElement.clientWidth

    if (this.headerIsWrapping){
      this.minHeaderScrollWidth = wrap_width
      this.columnHeaderTargets.forEach(target => target.classList.add("vertical"))
    }
  }

  checkHeaderNotWrapping() {
    this.headerIsWrapping = this.minHeaderScrollWidth > this.largestHeaderEl.parentElement.clientWidth

    if (!this.headerIsWrapping){
      this.columnHeaderTargets.forEach(target => target.classList.remove("vertical"))
    }
  }

  evaluateWrap(event) {
    if (this.hasSubmissionButtonTarget){
      this.submissionIsWrapping ? this.checkButtonNotWrapping() : this.checkButtonWrapping()
    }

    if (this.hasColumnHeaderTarget){
      this.headerIsWrapping ? this.checkHeaderNotWrapping() : this.checkHeaderWrapping()
    }
  }
}
