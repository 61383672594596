import { Controller } from "stimulus"

import Sortable from "sortablejs"

export function hide(el) {
  el.style.display = "none"
}

export function show(el) {
  el.style.display = ""
}

export default class extends Controller {
  static targets = ["startButton", "stopButton", "table"]

  connect() {
    hide(this.stopButtonTarget)
  }

  start(event) {
    event.preventDefault()

    show(this.stopButtonTarget)
    hide(this.startButtonTarget)

    this.createSortable()
  }

  stop(event) {
    event.preventDefault()

    show(this.startButtonTarget)
    hide(this.stopButtonTarget)

    this.sortable.destroy()
  }

  createSortable() {
    const sortParent = this.tableTarget.querySelector("tbody")
    this.sortable = Sortable.create(sortParent, {
      onEnd: (event) => { this.syncItemPosition(event.item, event.newIndex) }
    })
  }

  syncItemPosition(el, pos) {
    const url = el.dataset.url
    const data = this.data.get("resource") + `[sequence_position]=${pos}`
    const token = document.getElementsByName("csrf-token")[0].content
    console.log(data)

    const req = new XMLHttpRequest()
    req.open("PUT", url, true)
    req.setRequestHeader("Content-Type", "application/x-www-form-urlencoded")
    req.setRequestHeader("Accept", "text/javascript");
    req.setRequestHeader("X-CSRF-Token", token)
    req.send(data)
  }
}
