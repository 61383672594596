import { Controller } from "stimulus"

import Rails from "@rails/ujs";

export function hide(el) {
  el.style.display = "none"
}

export function show(el) {
  el.style.display = ""
}

export default class extends Controller {
  static targets = ["correctButton", "incorrectButton", "resetButton", "revealSolutionButton"]

  connect() {
    this.id = this.data.get("id")
    this.url = this.data.get("url")
    this.status = this.data.get("status")
    this.revealSolution = JSON.parse(this.data.get("reveal-solution"))

    this.updateMarkingButtons()
    this.updateRevealToggle()
  }

  markCorrect(event) {
    event.preventDefault()

    this.status = "correct"
    this.updateSubmission()
  }

  markIncorrect(event) {
    event.preventDefault()

    this.status = "incorrect"
    this.updateSubmission()
  }

  resetMarking(event) {
    event.preventDefault()

    this.status = "unmarked"
    this.updateSubmission()
  }

  toggleReveal(event) {
    event.preventDefault()

    this.revealSolution = !this.revealSolution
    this.updateSubmission()
  }

  updateSubmission() {
    Rails.ajax({
      type: "put",
      url: this.url,
      data: `submission[status]=${this.status}&submission[reveal_solution]=${this.revealSolution}`,
      success: this.updatePageElements(),
      error: function(response){
        var errors = JSON.parse(response).errors
        alert(errors)
      }
    })
  }

  updatePageElements(){
    this.updateSelf()
    this.updateModal()
    this.updateUnderlying()
  }

  updateSelf() {
    this.updateMarkingButtons()
    this.updateRevealToggle()
  }

  updateMarkingButtons() {
    if (this.status == "correct" || this.status == "incorrect") {
      hide(this.correctButtonTarget)
      hide(this.incorrectButtonTarget)
      show(this.resetButtonTarget)
    } else {
      hide(this.resetButtonTarget)
      show(this.correctButtonTarget)
      show(this.incorrectButtonTarget)
    }
  }

  updateRevealToggle() {
    this.revealSolutionButtonTarget.classList.toggle("on", this.revealSolution)
  }

  updateModal(){
    var modal = document.getElementById("modal")
    if (modal) modal.querySelector(".marking-status").innerHTML = this.status
  }

  updateUnderlying() {
    this.updateUnderlyingSubmisionButton()
    this.updateUnderlyingMarkingTable()
  }

  updateUnderlyingSubmisionButton() {
    var submission = document.getElementsByClassName(`submission-${this.id}`)[0]

    // Set class on submission
    submission.classList.remove("unmarked")
    submission.classList.remove("correct")
    submission.classList.remove("incorrect")
    submission.classList.add(this.status)

    // Update submission list for a task
    if (document.getElementById("task")) {
      submission.querySelector(".marking-status").innerHTML = this.status
    }
  }

  updateUnderlyingMarkingTable() {
    var controllerEl = document.querySelector(`.marking-table [data-marking-id="${this.id}"]`)
    var controller = this.getControllerForElement(controllerEl)

    if (controller && controller != this) {
      controller.status = this.status
      controller.revealSolution = this.revealSolution
      controller.updateSelf()
    }
  }

  getControllerForElement(el) {
    return this.application.getControllerForElementAndIdentifier(el, this.identifier)
  }
}
