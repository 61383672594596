import { Controller } from "stimulus"

import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["revealSolutionButton"]

  connect() {
    this.revealableId = this.data.get("revealableId")
    this.revealableType = this.data.get("revealableType")
    this.url = this.data.get("url")
    this.revealed = JSON.parse(this.data.get("revealed"))

    this.updateRevealSolutionButton()
  }

  toggleReveal(event) {
    event.preventDefault()

    this.revealed = !this.revealed
    this.updateSolutionReveal()
  }

  updateSolutionReveal() {
    Rails.ajax({
      type: this.revealed ? "post" : "delete",
      url: this.url,
      data: `solution_reveal[revealable_id]=${this.revealableId}&solution_reveal[revealable_type]=${this.revealableType}`,
      success: this.updateRevealSolutionButton()
    })
  }

  updateRevealSolutionButton() {
    this.revealSolutionButtonTarget.classList.toggle("on", this.revealed)
  }
}
