import { Controller } from "stimulus"

export default class extends Controller {
  close(event) {
    if(event.target == event.currentTarget) {
      event.preventDefault()
      this.element.remove()
    }
  }
}
