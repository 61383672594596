import { Controller } from "stimulus"

export default class extends Controller {
  remove(event) {
    event.preventDefault()

    const removeEvent = new CustomEvent('removeFile', {
      detail: {fileId: this.data.get("fileId")},
      bubbles: true
    });

    this.element.dispatchEvent(removeEvent)
  }
}
