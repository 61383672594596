import { Controller } from "stimulus"

import Uppy from "@uppy/core"
import DragDrop from "@uppy/drag-drop"
import StatusBar from "@uppy/status-bar"

import ActiveStorageUpload from "uppy-activestorage-upload"

import arrayToSentence from 'array-to-sentence'

import "@uppy/core/dist/style.css"
import "@uppy/drag-drop/dist/style.css"
import "@uppy/status-bar/dist/style.css"

export function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling)
}

export default class extends Controller {
  static targets = ["drop", "field", "fileList", "fileListWrapper", "status"]

  connect() {
    this.extensions = JSON.parse(this.data.get("extensions"))
    this.form = this.element.closest("form")
    this.fileUploads = {}
    this.hiddenInputs = {}

    this.uppy = this.createUppy(this.dropTarget, this.fieldTarget)
    this.fieldTarget.style.display = "none"

    this.form.addEventListener("reset", this.reset.bind(this))
  }

  disconnect() {
    this.form.removeEventListener("reset", this.reset.bind(this))
  }

  removeFile(event) {
    this.uppy.removeFile(event.detail.fileId)
  }

  reset() {
    this.uppy.reset()
  }

  createUppy(drop, field) {
    let note, allowedFileTypes

    if (this.extensions.length === 0) {
      note = null
      allowedFileTypes = null
    } else {
      const extensionList = arrayToSentence(this.extensions)
      note = `Only ${extensionList} files permitted.`
      allowedFileTypes = this.extensions.map(el => "." + el)
    }

    return Uppy({
      autoProceed: true,
      restrictions: {
        allowedFileTypes: allowedFileTypes
      }
    }).use(ActiveStorageUpload, {
      directUploadUrl: field.dataset.directUploadUrl
    }).use(DragDrop, {
      target: drop,
      height: "initial",
      note: note,
      locale: {
        strings: {
          dropHereOr: "Drag files here or %{browse}",
          browse: "Browse"
        }
      }
    }).use(StatusBar, {
      target: this.statusTarget,
      hideAfterFinish: false
    }).on('upload-success', (file, response) => {
      this.createFileUpload(file)
      this.createHiddenInput(file, response)
    }).on('file-removed', (file) => {
      this.removeFileUpload(file)
      this.removeHiddenInput(file)
    })
  }

  createFileUpload(file) {
    const item = document.createElement("li")
    item.innerHTML = file.name
    item.dataset.controller = "upload-file"
    item.dataset.uploadFileFileId = file.id

    const remove = document.createElement("a")
    remove.innerHTML = "×"
    remove.href = "#"
    remove.classList.add("remove")
    remove.dataset.action = "click->upload-file#remove"
    item.appendChild(remove)

    this.fileUploads[file.id] = item
    this.fileListTarget.appendChild(item)

    this.fileListWrapperTarget.classList.remove("hidden")
  }

  removeFileUpload(file) {
    const item = this.fileUploads[file.id]
    item.remove()
    delete this.fileUploads[file.id]
  }

  createHiddenInput(file, response) {
    const input = document.createElement("input")
    input.type = "hidden"
    input.name = this.fieldTarget.name
    input.value = response.signed_id

    this.hiddenInputs[file.id] = input
    insertAfter(input, this.fieldTarget)
  }

  removeHiddenInput(file) {
    const input = this.hiddenInputs[file.id]
    input.remove()
    delete this.hiddenInputs[file.id]
  }
}
